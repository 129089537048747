@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* GENERAL CSS */

* {
  -webkit-locale: auto;
  white-space: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: Roboto;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
}

h3 {
  font-weight: 500;
}

h4 {
  font-weight: 700;
}

html body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  background-color: #121317;
}
label span{
    margin-left: 10px;
}

input[type="radio"]{
  
width: auto;
margin-top: 30px;
display: inline-block;
}

#radioButtonId0{
  margin-top: 0px;

}
.radioButtonsCenter{
  text-align: center;
  margin: 0 auto;
  width: 350px;
  
}
.radioButtonsLeft{
  margin: 0 auto;
  width: auto;
  text-align: left;
  display: table;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 1.5;
  padding-bottom: 45px;


}

input[type='radio']:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color:#262931;
  content: '';
  display: inline-block;
  visibility: visible;
  border:3px solid #262931;
  box-shadow: 0 0 0 2px #A1AAB3;
  z-index: 0;
}

input[type='radio']:checked:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #2789FF ;
  content: '';
  display: inline-block;
  visibility: visible;
  border:3px solid #262931;
  box-shadow: 0 0 0 2px #A1AAB3;
  z-index: 0;

}

label {
  display: block;
  text-indent: -29px;
  margin-left: 30px;
} 

.App {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  background-color: #121317;
  font-family: Roboto;
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
}

.BlockGrid {
  min-width: 320px;
  max-width: 1024px;
  min-height: 100vh;
  height: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  margin: 0 auto;
  background-color: #262931;
  display: block;
  justify-content: center;
  width: 100%;
}

.Header {
  padding-top: 30px;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.Logo {
  height: 50px;
  pointer-events: none;
}

.Container {
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
}

.Info-box {
  border-radius: 5px;
  font-size: 18px;
  width: 100%;
  text-align: center;
  font-family: Roboto;
}

.Info-box h4 {
  text-align: center;
  font-size: 24px;
}

.Info-box h3 {
  color: #d8d8d8;
  text-align: center;
}
.unsubHeadline{
  text-align: center;
  width: 80%;
  padding-left: 10%;
}

.RecommendationsTitle {
  font-size: 20px;
  text-align: center;
}

.RecommendationsContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
}

.RecommendationsItem > a > span {
  display: block !important;
}

.RecommendationsItem {
  width: 49%;
  padding: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  justify-content: center;
  text-align: center;
}

.RecommendationsItemPortrait {
  width: 31%;
  padding: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  justify-content: center;
  text-align: center;
}

.RecommendationsItem > p {
  font-size: 20px;
  position: relative;
}

.RecommendationsItemImage {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
}

.DescriptionContainer {
  position: relative;
  display: inline-block;
  height: 130px;
  width: 100%;
}

.Description {
  position: absolute;
  height: 100%;
  text-align: center;
  vertical-align: top;
  z-index: 1;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}

.Description a {
  color: #d8d8d8;
  cursor: pointer;
  text-decoration: none;
}

.Description:before {
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.Description h4 {
  font-size: 20px; /*or whatever you want*/
  margin-bottom: 10px;
  margin-top: 15px;
}

.Description h3 {
  margin-top: 0px;
  font-size: 16px;
  margin-bottom: 0px;
  color: #d8d8d8;
}

.FadedImage {
  display: inline-block;
  width: 100%;
  height: 120px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
          mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
}

.FlippedImage {
  display: inline-block;
  max-width: 100%;
  width: 100%;
  transform: scaleX(-1) rotate(180deg);
}

.ButtonsContainer {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  margin-bottom: 5vh;
  justify-content: space-between;
}

.UnsubscribeButton {
  background-color: #000000;
  border: none;
  color: #ffffff;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
  font-weight: bold;
  padding: 1rem 1rem;
  margin-bottom: 0.5rem;
  transition-duration: 0.4s;
}

.UnsubscribeButton:hover {
  background-color: #ffffff; /* Green */
  color: #000000;
}

.StaySubscribedButton {
  background-color: rgb(33, 117, 217);
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
  font-weight: bold;
  padding: 1rem 1rem;
  transition: background 0.2s ease-in-out 0s;
    transition-property: background;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.StaySubscribedButton:hover {
  background-color: #2889FF
  ;
}

.UnsubscribeButton.focus,
.UnsubscribeButton:focus,
.StaySubscribedButton.focus,
.StaySubscribedButton:focus {
  outline: 0;
  box-shadow: none !important;
}

/* GENERAL DESKTOP LAYOUT */
@media only screen and (min-width: 771px) {
  .ButtonsContainer {
    flex-direction: row-reverse;
  }
  .Info-box h3 {
    font-size: 18px;
  }
  .UnsubscribeButton {
    margin: 0;
    width: 49%;
  }

  .StaySubscribedButton {
    margin: 0;
    width: 49%;
  }

  .Success {
    width: 50%;
    margin: 0 auto;
  }

  .Error {
    width: 40%;
    margin: 0 auto;
  }
}

/* FOR PHONE LAYOUTS */
@media (max-width: 770px) {
  .BlockGrid {
    width: 100% !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .Container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .Info-box {
    padding-left: 0px;
    padding-right: 0px;
  }

  .Info-box h3 {
    font-size: 16px;
  }

  .RecommendationsItem {
    width: 100%;
  }
  
  .RecommendationsItemPortrait {
    width: 100%;
    margin: 0 20%;
  }

  .RecommendationCarousel {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .UnsubscribeButton {
    margin-bottom: 20px;
    width: 100%;
  }

  .StaySubscribedButton {
    width: 100%;
  }

  .Success {
    width: 80%;
    margin: 0 auto;
  }

  .Error {
    width: 60%;
    margin: 0 auto;
  }

  .Description {
    padding-right: 15px;
    padding-left: 15px;
  }

  .radioButtonsCenter{
    text-align: center;
    margin: 0 auto;
    width: 100%;
    
  }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .FadedImage {
    display: none;
  }
  .BlockGrid {
    text-align: left;
  }
  .Error {
    margin-top: 200px;
  }

  .Success {
    margin-top: 200px;
  }
}

